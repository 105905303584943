/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Title from "../Title/Title";
import Button from "../Button/Button";
import Paragraph from "../Paragraph/Paragraph";
import styles from "./Result.module.scss";
import classNames from "classnames";
import {
  VKShareButton,
  OKShareButton,
  ViberShareButton,
  WhatsappShareButton,
  TelegramShareButton,
} from "react-share";
import {
  VKIcon,
  OKIcon,
  ViberIcon,
  WhatsappIcon,
  TelegramIcon,
} from "react-share";

function Result({
  title,
  text1,
  text2,
  text3,
  imageSrc,
  posterContainer,
  optionsContainer,
  handlerBack,
  job,
}) {
  const [offset, setOffset] = useState(false);

  useEffect(() => {
    const handleScroll = (event) => {
      if (window.scrollY > 50) {
        setOffset(true);
      } else {
        setOffset(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const SiteUrl = "https://driverquiz.taximaximapp.ru";

  return (
    <>
      <div className={posterContainer}>
        <img src={imageSrc} className={styles.image} alt="" />
      </div>
      <div className={classNames(optionsContainer, styles.before)}>
        <div
          className={classNames({
            [styles.resultPosterContainer]: true,
            [styles.onScrollOption]: offset,
          })}
        >
          <Title value={title} />
          <Paragraph value={text1} />
          <Paragraph value={text2} />
          <Paragraph value={text3} strong />
          <div className={styles.buttonBox}>
            <a
              href="https://taxsee.pro/ru/ru-RU/"
              className={styles.button}
              id="goToDriver"
              target="_blank"
              rel="noreferrer"
            >
              {"Стать " + job}
            </a>
            <Button
              value="Пройти заново"
              id="refresh"
              className={styles.outline}
              onClick={handlerBack}
            />
          </div>

          <div>
            <Paragraph value="Поделиться результатом" />

            <div className={styles.social}>
              <VKShareButton
                url={SiteUrl}
                title="Как выглядит ваш идеальный вариант подработки?"
                image={SiteUrl + imageSrc}
                hashtag="#Maxim"
                id="vk1"
                className={styles.buttonIcon}
              >
                <VKIcon
                  size={48}
                  round={true}
                  className={classNames(styles.icon, styles.vk)}
                  id="vk2"
                />
              </VKShareButton>

              <OKShareButton
                url={SiteUrl}
                title="Как выглядит ваш идеальный вариант подработки?"
                image={SiteUrl + imageSrc}
                description={text1}
                id="ok1"
                className={styles.buttonIcon}
              >
                <OKIcon
                  size={48}
                  round={true}
                  className={classNames(styles.icon, styles.ok)}
                  id="ok2"
                />
              </OKShareButton>

              <ViberShareButton
                url={SiteUrl}
                title="Как выглядит ваш идеальный вариант подработки?"
                image={SiteUrl + imageSrc}
                id="viber1"
                className={styles.buttonIcon}
              >
                <ViberIcon
                  size={48}
                  round={true}
                  className={classNames(styles.icon, styles.viber)}
                  id="viber2"
                />
              </ViberShareButton>

              <WhatsappShareButton
                url={SiteUrl}
                title="Как выглядит ваш идеальный вариант подработки?"
                image={SiteUrl + imageSrc}
                id="whatsapp1"
                className={styles.buttonIcon}
              >
                <WhatsappIcon
                  size={48}
                  round={true}
                  className={classNames(styles.icon, styles.whatsapp)}
                  id="whatsapp2"
                />
              </WhatsappShareButton>

              <TelegramShareButton
                url={SiteUrl}
                title="Как выглядит ваш идеальный вариант подработки?"
                image={SiteUrl + imageSrc}
                id="telegram1"
                className={styles.buttonIcon}
              >
                <TelegramIcon
                  size={48}
                  round={true}
                  className={classNames(styles.icon, styles.telegram)}
                  id="telegram2"
                />
              </TelegramShareButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Result;
