const questions = [
  {
    questionText:
      "Вы решили подработать. Сколько готовы тратить времени на это?",
    answerOptions: [
      {
        answerText: "Чем меньше, тем лучше.",
        answerResult:
          "Понимаем! Кстати, в «Максим» просто строить график самостоятельно и работать в комфортном режиме.",
      },
      {
        answerText: "Часа четыре каждый день. Люблю планировать время заранее.",
        answerResult:
          "Понимаем! Кстати, в «Максим» просто строить график самостоятельно и работать в комфортном режиме.",
      },
      {
        answerText:
          "Сам себе режиссер: хочу — два часа поработаю, хочу — 12, а хочу — выходной устрою.",
        answerResult:
          "Понимаем! Кстати, в «Максим» просто строить график самостоятельно и работать в комфортном режиме.",
      },
    ],
    image: "images/01.webp",
  },

  {
    questionText:
      "Что сделаете с основной работой, если вдруг придется переехать?",
    answerOptions: [
      {
        answerText: "Поговорю с начальством, вдруг можно работать удаленно.",
        answerResult:
          "Принимаем ваш выбор! Водители и курьеры сервиса работают в Казахстане, Грузии и еще 15 странах! Доход можно получать в любом городе, где работает «Максим».",
      },
      {
        answerText: "Придется увольняться и искать новую должность.",
        answerResult:
          "Принимаем ваш выбор! Водители и курьеры сервиса работают в Казахстане, Грузии и еще 15 странах! Доход можно получать в любом городе, где работает «Максим».",
      },
      {
        answerText:
          "Уволюсь и сразу возьму несколько подработок, чтобы не остаться без денег.",
        answerResult:
          "Принимаем ваш выбор! Водители и курьеры сервиса работают в Казахстане, Грузии и еще 15 странах! Доход можно получать в любом городе, где работает «Максим».",
      },
    ],
    image: "images/02.webp",
  },

  {
    questionText:
      "Представьте, что вам предстоит серьезный разговор с начальником. Будете переживать?",
    answerOptions: [
      {
        answerText: "Нет. Знаю, что работаю на все 100 %.",
        answerResult:
          "Радует, что с «Максим» вы сами себе начальник. Можете и похвалить себя, и замотивировать.",
      },
      {
        answerText: "Да! Если скажут, что есть ошибки, буду исправлять.",
        answerResult:
          "Радует, что с «Максим» вы сами себе начальник. Можете и похвалить себя, и замотивировать.",
      },
      {
        answerText: "Конечно! Каждый раз это настоящий стресс.",
        answerResult:
          "Радует, что с «Максим» вы сами себе начальник. Можете и похвалить себя, и замотивировать.",
      },
    ],
    image: "images/03.webp",
  },

  {
    questionText: "Продолжите: «Современные технологии я…» ",
    answerOptions: [
      {
        answerText: "Обожаю! В курсе всех новинок мира технологий.",
        answerResult:
          "За технологиями — будущее. В сервисе есть современное приложение для водителей и курьеров Taxsee Driver (0+). Доступно в маркетах, установить на смартфон просто за пару секунд. ",
      },

      {
        answerText: "Уважаю! Здорово, что технологии развиваются.",
        answerResult:
          "За технологиями — будущее. В сервисе есть современное приложение для водителей и курьеров Taxsee Driver (0+). Доступно в маркетах, установить на смартфон просто за пару секунд. ",
      },
      {
        answerText: "Не против технологий, но за новшествами не слежу.",
        answerResult:
          "За технологиями — будущее. В сервисе есть современное приложение для водителей и курьеров Taxsee Driver (0+). Доступно в маркетах, установить на смартфон просто за пару секунд. ",
      },
    ],
    image: "images/04.webp",
  },

  {
    questionText:
      "У вас выходной. Какой фильм или сериал для просмотра выберете?",
    answerOptions: [
      {
        answerText:
          "«Такси». С детства люблю фильмы про машины. Сейчас мастерски вожу авто, как Даниэль.",
        answerResult:
          "Отличный выбор! Осталось всего 3 вопроса, и узнаем, какой вариант подработки вам подходит.",
        category: "cat1",
      },
      {
        answerText:
          "«Дальнобойщики». Нравятся фильмы с романтикой дальних дорог. За рулем своего грузовика чувствую себя отлично.",
        answerResult:
          "Отличный выбор! Осталось всего 3 вопроса, и узнаем, какой вариант подработки вам подходит.",
        category: "cat2",
      },
      {
        answerText:
          "«Форрест Гамп». Фильмы про мечты — самые любимые. Как герой, двигаюсь вперед на своих двоих.",
        answerResult:
          "Отличный выбор! Осталось всего 3 вопроса, и узнаем, какой вариант подработки вам подходит.",
        category: "cat3",
      },
    ],
    image: "images/05.webp",
  },

  {
    questionText: "Представьте, что зарплата задерживается. Ваша первая мысль?",
    answerOptions: [
      {
        answerText: "Наверное, ошибка, подожду 1–2 дня.",
        answerResult:
          "Хорошо, что в «Максим» доход можно получать каждый день. Деньги — сразу после выполнения заказа. И никаких задержек!",
      },
      {
        answerText: "Надо узнать причину! Уже иду к бухгалтеру.",
        answerResult:
          "Хорошо, что в «Максим» доход можно получать каждый день. Деньги — сразу после выполнения заказа. И никаких задержек!",
      },
      {
        answerText: "Что за новости! Деньги мне нужны прямо сейчас.",
        answerResult:
          "Хорошо, что в «Максим» доход можно получать каждый день. Деньги — сразу после выполнения заказа. И никаких задержек!",
      },
    ],
    image: "images/06.webp",
  },

  {
    questionText:
      "«Комфортнее работать, когда точно знаю, что делать». Это про вас?",
    answerOptions: [
      {
        answerText: "Абсолютно точно! Неуверенность от неизвестности.",
        answerResult:
          "В «Максим» все данные о заказе, например маршрут и цена, известны заранее. Удобно!",
      },
      {
        answerText: "Скорее, да. Мне нравится действовать по плану.",
        answerResult:
          "В «Максим» все данные о заказе, например маршрут и цена, известны заранее. Удобно!",
      },
      {
        answerText: "Зависит от ситуации. Но могу работать в форс-мажоре.",
        answerResult:
          "В «Максим» все данные о заказе, например маршрут и цена, известны заранее. Удобно!",
      },
    ],
    image: "images/07.webp",
  },

  {
    questionText:
      "Представьте, что перед приемом на работу вам надо пройти собеседование. Как к этому отнесетесь?",
    answerOptions: [
      {
        answerText: "Нейтрально. Хотя считаю это утомительным.",
        answerResult:
          "Понимаем! В сервисе есть онлайн-регистрация: подключиться можно на сайте в любое время. Это займет примерно три минуты.",
      },
      {
        answerText:
          "Скорее негативно. Желательно устроиться на работу онлайн, когда мне удобно.",
        answerResult:
          "Понимаем! В сервисе есть онлайн-регистрация: подключиться можно на сайте в любое время. Это займет примерно три минуты.",
      },
      {
        answerText: "Считаю, что это прошлый век.",
        answerResult:
          "Понимаем! В сервисе есть онлайн-регистрация: подключиться можно на сайте в любое время. Это займет примерно три минуты.",
      },
    ],
    image: "images/08.webp",
  },
];

export default questions;
