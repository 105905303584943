import Title from "./components/Title/Title";
import Paragraph from "./components/Paragraph/Paragraph";
import Button from "./components/Button/Button";
import OptionsItem from "./components/OptionsItem/OptionsItem";
import React, { useEffect, useState } from "react";
import imagePrev from "./assets/images/00.webp";
import imageResult1 from "./assets/images/91.webp";
import imageResult2 from "./assets/images/92.webp";
import imageResult3 from "./assets/images/93.webp";
import styles from "./App.module.scss";
import questions from "./state/questions";
import classNames from "classnames";
import Result from "./components/Result/Result";

function App() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [stateFlow, setStateFlow] = useState("Prev");
  const [option, setOption] = useState(false);
  const [resultState, setResultState] = useState("");

  const [counterCat1, setCounterCat1] = useState(1);
  const [counterCat2, setCounterCat2] = useState(1);
  const [counterCat3, setCounterCat3] = useState(1);

  const optionActiveHandler = (option) => {
    setOption(option);

    setTimeout(() => {
      window.scrollTo({ top: 1000, behavior: "smooth" });
    }, "50");
  };

  const catOne = (cat1) => {
    setCounterCat1(counterCat1 + cat1);
  };

  const catTwo = (cat2) => {
    setCounterCat2(counterCat2 + cat2);
  };

  const catThree = (cat3) => {
    setCounterCat3(counterCat3 + cat3);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handlerNext = () => {
    if (currentQuestion === 7) {
      setStateFlow("End");

      setResultState(() => {
        if (counterCat1 >= counterCat2 && counterCat1 >= counterCat3) {
          setResultState(() => {
            return (
              <Result
                title="Я Евгений, приятно познакомиться!"
                text1="Мастерски управляю легковым автомобилем и ценю время, прямо как вы"
                text2="Обычно выполняю заказы в свободное время. Поехал из офиса домой, подвез пассажиров и получил деньги. Это удобно!"
                text3="Хотите зарабатывать как водитель такси? Присоединяйтесь к сервису  «Максим» — регистрируйтесь на сайте taxsee.pro"
                imageSrc={imageResult1}
                posterContainer={styles.posterContainer}
                optionsContainer={styles.optionsContainer}
                handlerBack={handlerBack}
                job="водителем"
              />
            );
          });

          scrollToTop();
        }

        if (counterCat2 >= counterCat1 && counterCat2 >= counterCat3) {
          setResultState(() => {
            return (
              <Result
                title="Я Илья, приятно познакомиться!"
                text1="Отлично управляю грузовым автомобилем, не боюсь браться за тяжелую работу и всегда приду на помощь, какой бы сложной ни была задача."
                text2="Раньше водил легковушку и зарабатывал в такси. Теперь есть грузовик. Мне нравится помогать людям при переездах или с доставкой крупных вещей и при этом получать доход. Всего пара заказов в день, и хорошая сумма в кармане. Выгодно!"
                text3="Хотите зарабатывать как водитель грузового авто? Присоединяйтесь к сервису  «Максим» — регистрируйтесь на сайте taxsee.pro"
                imageSrc={imageResult2}
                posterContainer={styles.posterContainer}
                optionsContainer={styles.optionsContainer}
                handlerBack={handlerBack}
                job="водителем"
              />
            );
          });

          scrollToTop();
        }

        if (counterCat3 >= counterCat1 && counterCat3 >= counterCat2) {
          setResultState(() => {
            return (
              <Result
                title="Я Дмитрий, приятно познакомиться!"
                text1="Моя сила — в скорости. Разношу посылки клиентам вмиг, потому что уважаю свое и чужое время."
                text2="Обычно работаю по выходным. Просто выполняю пару доставок и получаю деньги на руки. Заказы беру в своем районе, рядом с домом: передвигаюсь пешком или на велосипеде."
                text3="Хотите зарабатывать как курьер? Присоединяйтесь к сервису  «Максим» — регистрируйтесь на сайте taxsee.pro"
                imageSrc={imageResult3}
                posterContainer={styles.posterContainer}
                optionsContainer={styles.optionsContainer}
                handlerBack={handlerBack}
                job="курьером"
              />
            );
          });

          scrollToTop();
        }
      });
    } else {
      setOption(false);
      setCurrentQuestion(currentQuestion + 1);

      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, "50");
    }
  };

  const handlerBack = () => {
    setOption(false);
    setStateFlow("Prev");
    setCurrentQuestion(0);
    setCounterCat1(1);
    setCounterCat2(1);
    setCounterCat3(1);
  };

  const [offset, setOffset] = useState(false);

  useEffect(() => {
    const handleScroll = (event) => {
      if (window.scrollY > 50) {
        setOffset(true);
      } else {
        setOffset(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className={styles.wrapperContainer}>
        {stateFlow === "Prev" ? (
          <>
            <div className={styles.posterContainer}>
              <img src={imagePrev} className={styles.image} alt="" />
            </div>

            <div className={styles.optionsContainer}>
              <div className={styles.optionsFirstScreen}>
                <Title value="Как выглядит ваш идеальный вариант подработки?" />
                <Paragraph value="Таксист, курьер или водитель грузовика — ответьте на 8 вопросов сервиса заказа такси «Максим» и познакомьтесь со своим идеальным вариантом подработки." />

                <div className={styles.buttonAbsolute}>
                  <Button
                    value="Вперед!"
                    id="start"
                    onClick={() => setStateFlow("Flow")}
                  />
                </div>
              </div>
            </div>
          </>
        ) : stateFlow === "Flow" ? (
          <>
            <div className={styles.posterContainer}>
              <img
                src={questions[currentQuestion].image}
                className={styles.image}
                alt={questions[currentQuestion].image}
              />
            </div>

            <div
              className={classNames({
                [styles.optionsContainer]: true,
                [styles.onScrollOption]: offset,
              })}
            >
              <div className={styles.bgWhite}>
                <div className={styles.header}>
                  <div className={styles.topBarContainer}>
                    <div>
                      Вопрос {currentQuestion + 1} из {questions.length}
                    </div>
                    <div onClick={handlerBack} className={styles.back}>
                      На главную
                    </div>
                  </div>
                  <div className={styles.titleWrapper}>
                    <Title value={questions[currentQuestion].questionText} />
                  </div>
                </div>

                <div className={styles.optionsWrapper}>
                  {questions[currentQuestion].answerOptions.map((item) => (
                    <OptionsItem
                      key={item.answerText}
                      title={item.answerText}
                      resultText={item.answerResult}
                      onClick={optionActiveHandler}
                      disabled={option}
                      category={item.category}
                      cat1={catOne}
                      cat2={catTwo}
                      cat3={catThree}
                    />
                  ))}
                </div>

                <div className={styles.buttonContainer}>
                  <Button
                    className={styles.buttonNotfix}
                    value="Далее"
                    onClick={() => handlerNext()}
                    disabled={!option}
                    id={"btn" + (currentQuestion + 1)}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>{resultState}</>
        )}
      </div>
    </>
  );
}

export default App;
